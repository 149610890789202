import Head from "next/head"
import PropTypes from "prop-types"

const MetaHead = ({ meta }) => {
  return (
    <Head>
      <title>{meta.title}</title>
      {meta.description && (
        <meta name="Description" content={meta.description} />
      )}
      <meta property="fb:pages" content="578755068892043" />
      <meta property="fb:admins" content="1286006747" />
      <meta property="fb:app_id" content="420222434791850" />
      <meta property="og:title" content={meta.title} />
      {meta.description && (
        <meta property="og:description" content={meta.description} />
      )}
      <meta property="og:type" content="website" />
      {meta.url && <meta property="og:url" content={meta.url} />}
      {meta.image && <meta property="og:image" content={meta.image} />}
      <meta property="og:site_name" content={meta.title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@nobody_surf" />
      {meta.url && <meta name="twitter:url" content={meta.url} />}
      <meta name="twitter:title" content={meta.title} />
      {meta.description && (
        <meta name="twitter:description" content={meta.description} />
      )}
      {meta.image && <meta name="twitter:image" content={meta.image} />}
      {meta.url && <link rel="canonical" href={meta.url} />}
      {meta.rss && (
        <link
          rel="alternate"
          href={meta.rss}
          type="application/rss+xml"
          title="RSS2.0"></link>
      )}
    </Head>
  )
}

MetaHead.propTypes = {
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      url: PropTypes.string,
      rss: PropTypes.string,
    }),
  ),
}

export default MetaHead
